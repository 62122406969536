import { ReclaimEventType, TimeScheme, TimeSchemeCreateRequest, TimeSchemeUpdateRequest } from "./TimeSchemes.types";
import { TransformDomain } from "./types";
import { TimeScheme as TimeSchemeDto, ReclaimEventType as ReclaimEventTypeDto } from "./client";
import { dtoToTimeScheme, timeSchemeCreateRequestToDto, timeSchemeUpdateRequestToDto } from "./TimeSchemes.mutators";

export class TimeSchemesDomain extends TransformDomain<TimeScheme, TimeSchemeDto> {
  resource = "TimeScheme";
  cacheKey = "time-schemes";
  pk = "id";

  list = this.typedManageErrors(async () => {
    // Only deal with custom time policies until the user policies are migrated
    const dto = await this.api.timeschemes.listTimePolicySchemes();
    return dto.map(dtoToTimeScheme);
  });

  create = this.typedManageErrors(async (scheme: TimeSchemeCreateRequest) => {
    const created = await this.api.timeschemes.createTimeScheme(timeSchemeCreateRequestToDto(scheme));
    return dtoToTimeScheme(created);
  });

  update = this.typedManageErrors(async (schemeId: string, scheme: TimeSchemeUpdateRequest) => {
    const updated = await this.api.timeschemes.updateTimeScheme1(schemeId, timeSchemeUpdateRequestToDto(scheme));
    return dtoToTimeScheme(updated);
  });

  delete = this.typedManageErrors(
    async (schemeId: string, moveToTimeSchemeId?: string) =>
      await this.api.timeschemes.deleteTimeScheme(schemeId, { moveToTimeSchemeId })
  );

  listTimePolicySchemesByUserIdAndFeature = this.typedManageErrors(
    async (userId: string, feature: ReclaimEventType) => {
      const schemes = await this.api.timeschemes.listTimePolicySchemesByUserIdAndFeature(userId, {
        feature: feature as ReclaimEventTypeDto,
      });

      return schemes.map(dtoToTimeScheme);
    }
  );
}
