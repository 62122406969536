import { addDays, isAfter, isFuture } from "date-fns";
import { dateToYyyyMmDd } from "../utils/dates";
import { analyticssMetricToDto, dtoToAnalyticsMetrics } from "./Analytics.mutators";
import { AnalyticsResults, AnalyticssMetric } from "./Analytics.types";
import { TransformDomain } from "./types";

export type GetTeamAnalyticsOptions =
  | { mode: "START_END"; start: Date; end?: Date }
  | { mode?: "DAYS_PAST"; daysIntoPast: number };

export class AnalyticsDomain extends TransformDomain<unknown, unknown> {
  resource = "Analytics";
  cacheKey = "analytics";

  getTeamAnalytics = this.typedManageErrors(
    async <T extends AnalyticssMetric[]>(
      metrics: T,
      options: GetTeamAnalyticsOptions
    ): Promise<AnalyticsResults<T>> => {
      let start: Date, end: Date;

      switch (options.mode) {
        case "DAYS_PAST":
        default:
          const d = new Date();
          start = addDays(d, -options.daysIntoPast);
          end = d;
          break;
        case "START_END":
          start = options.start;
          end = options.end || new Date();
          break;
      }

      if (isFuture(start)) throw new Error("Start date may not be in the future");
      if (isAfter(start, end)) throw new Error("End cannot come after start");

      return dtoToAnalyticsMetrics(
        await this.api.analytics.analyticsTeamAnalytics({
          start: dateToYyyyMmDd(start),
          end: dateToYyyyMmDd(end),
          metricName: metrics.map(analyticssMetricToDto),
        })
      );
    }
  );
}
