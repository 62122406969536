import { MouseEventHandler, VFC } from "react";
import { useAnalyticsContext } from "../context/AnalyticsContext";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";
import { ButtonWithLinkVariant, ButtonWithLinkVariantProps } from "./ButtonWithLinkVariant";

export type ContactSupportButtonProps = Omit<ButtonWithLinkVariantProps, ""> & {
  lead?: string;
};

export const ContactSupportButton: VFC<ContactSupportButtonProps> = ({ children, onClick, lead, ...rest }) => {
  /********************/
  /*   custom hooks   */
  /********************/

  const {
    state: { intercom },
  } = useAnalyticsContext();

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  /********************/
  /*    useCallback   */
  /********************/

  const handleClick = useCallbackSafeRef<MouseEventHandler<HTMLButtonElement>>(async (e) => {
    onClick?.(e);
    intercom?.("showNewMessage", lead);
  });

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <ButtonWithLinkVariant variant="link" size="small" {...rest} onClick={handleClick}>
      {children || <>Contact&nbsp;us</>}
    </ButtonWithLinkVariant>
  );
};
