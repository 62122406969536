import { CommandBarClientSDK, init } from "commandbar";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";
import { useOurRouter } from "../hooks/useOurRouter";
import { initHabitData, initTaskData } from "../utils/commandbar";
import { Browser } from "../utils/platform";
import { useAnalyticsContext } from "./AnalyticsContext";
import { useUserContext } from "./UserContext";

export type CommandBarContextState = {
  CommandBar?: CommandBarClientSDK;
  status: string;
};

export type CommandBarContextValue = {
  state: CommandBarContextState;
};

export const defaultState: CommandBarContextState = {
  get CommandBar() {
    return Browser.isBrowser() ? window?.CommandBar : undefined;
  },
  status: "init",
};

export const CommandBarContext = createContext<CommandBarContextValue>({
  state: defaultState,
});

export function useCommandBarContext() {
  return useContext<CommandBarContextValue>(CommandBarContext);
}

export const CommandBarContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<CommandBarContextState>(defaultState);

  const {
    state: { intercom },
  } = useAnalyticsContext();

  const router = useOurRouter();
  const dataInitialized = useRef<boolean>(false);

  const [userState] = useUserContext();

  const isBrowser = useMemo(() => Browser.isBrowser(), []);

  const handleLaunchSupport = useCallbackSafeRef(() => {
    intercom?.("showNewMessage", "I need help...");
  });

  useEffect(() => {
    if (!isBrowser || "init" !== state.status) return;
    // console.log("[CommandBar] init");

    // Init CommandBar
    init("84721cdb");
  }, [isBrowser, state.status]);

  useEffect(() => {
    if ("init" !== state.status) return;

    if (!isBrowser) {
      // console.warn("[CommandBar] Abort boot, no window");
      return;
    }
    if (!window.CommandBar) {
      // console.warn("[CommandBar] Abort boot, no window.CommandBar");
      return;
    }
    if (!userState.user?.trackingCode) {
      // console.warn("[CommandBar] Abort boot, no user id");
      return;
    }
    if (!userState.user?.onboarded) {
      // console.warn("[CommandBar] Abort boot, user not onboarded");
      return;
    }

    void window.CommandBar.boot(userState.user.trackingCode);

    setState((prev) => {
      prev.status = "boot";
      return prev;
    });
  }, [state.status, userState.user?.onboarded, userState.user?.trackingCode, isBrowser]);

  /* Handle status changes */
  useEffect(() => {
    if (!state.CommandBar) {
      // console.log("[CommandBar] not loaded");
      return;
    }

    switch (state.status) {
      case "boot":
        // console.log("[CommandBar] boot");
        // Set default context
        state.CommandBar.setContext({
          user: userState.user,
        });
        setState((prev) => {
          prev.status = "context";
          return prev;
        });
        break;
      case "context":
        // console.log("[CommandBar] context");
        break;
      case "ok":
        // console.log("[CommandBar] ok");
        break;
    }
  }, [state.CommandBar, state.status, userState.user]);

  useEffect(() => {
    // Load context when everything is available
    if (
      !state.CommandBar ||
      "context" !== state.status ||
      !router.isReady ||
      !userState.isAuthenticated ||
      !userState.user?.onboarded ||
      !!process.env.TEST_ENV
    )
      return;

    // Register router
    state.CommandBar.addRouter((url: string) => router.push(url));

    void state.CommandBar.addEventSubscriber((name) => {
      /**
       * On the first open event load habit and task data for search
       */
      if (name === "opened" && !dataInitialized.current) {
        dataInitialized.current = true;
        initTaskData(state.CommandBar);
        initHabitData(state.CommandBar);
      }
    });

    // Add callback
    state.CommandBar.addCallback("launchSupport", handleLaunchSupport);

    /**
     * TODO (SS): This is not being used but would show up in commadbar search if we added an action
     * like jump to page. If this is removed, remove the record type in CB editor.
     */
    state.CommandBar.addRecords("pages", ["planner", "stats", "tasks", "habits", "settings"]);

    state.CommandBar.addMetadata("route", {
      route: router.route,
      pathname: router.pathname,
      asPath: router.asPath,
      params: router.params,
      query: router.query,
    });

    setState((prev) => {
      prev.status = "ok";
      return prev;
    });
  }, [router, state, userState.isAuthenticated, userState.user, handleLaunchSupport]);

  return <CommandBarContext.Provider value={{ state }}>{children}</CommandBarContext.Provider>;
};

export default CommandBarContext;
