import { FC, ReactNode } from "react";
import { Status } from "../subpages/status";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import { ErrorBoundaryProps, FallbackRender } from "@sentry/react";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";

export type ReclaimErrorBoundaryFn<T> = (data: Parameters<FallbackRender>[0]) => T;
export type ReclaimErrorBoundaryValue<T> = T | ReclaimErrorBoundaryFn<T>;

export type ReclaimErrorBoundaryProps = {
  statusMessage?: ReclaimErrorBoundaryValue<ReactNode>;
  failureCode?: ReclaimErrorBoundaryValue<number>;
  supressExceptionCapture?: ReclaimErrorBoundaryValue<boolean>;
} & ErrorBoundaryProps;

export const ReclaimErrorBoundary: FC<ReclaimErrorBoundaryProps> = ({
  children,
  statusMessage = "We are so sorry, but there seems to be an issue. Our engineers have been notified and are working on a fix.",
  failureCode = 5000,
  supressExceptionCapture,
  ...rest
}) => {
  const handleFallback = useCallbackSafeRef<FallbackRender>((data) => {
    const callFn = <T extends unknown>(fn: ReclaimErrorBoundaryValue<T>): T =>
      typeof fn === "function" ? (fn as ReclaimErrorBoundaryFn<T>)(data) : fn;

    return (
      <Status
        code={callFn(failureCode)}
        message={callFn(statusMessage)}
        captureStatus={!callFn(supressExceptionCapture)}
        associatedError={data.error}
      />
    );
  });

  return (
    <SentryErrorBoundary fallback={handleFallback} showDialog {...rest}>
      {children}
    </SentryErrorBoundary>
  );
};

export default ReclaimErrorBoundary;
