import { NextRouter } from "next/router";
import { useEffect } from "react";
import { Url } from "url";
import { BookingPageAction } from "../components/forms/booking/BookingForm.types";
import { UserOnboardingStep } from "../components/onboarding/users/user-onboarding.types";
import { useOurRouter } from "../hooks/useOurRouter";
import { SchedulingLink, TimePolicyType } from "../reclaim-api/scheduling-links/SchedulingLinks";
import { TeamMember, TeamRedirectAction } from "../reclaim-api/team/Team";
import { getSessionStorage, setSessionStorage } from "./local-storage";

export type PushHistoryBackRouterLike = Pick<NextRouter, "push" | "asPath">;

const removeQS = (url: string) => url.replace(/\?.*$/, "");

const ON_SITE_HISTORY_STORAGE_KEY = "router.onSiteHistory";
const ON_SITE_HISTORY_SIZE = 10;
const DONT_ADD_TO_HISTORY_PATTERNS: RegExp[] = [
  /\[id\]/, // router.asPath briefly has the "[id]" slug on reloads.  Unsure if this is a problem in our code, or Next.js.
  /^\/\?login=/, // login redirect
];

const NEXT_PUBLIC_API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

/**
 * React hook to track history for pushHistoryBack.  Should be put at the root of the App.
 * @param router the current router
 */
export function useRecordHistory(router: PushHistoryBackRouterLike): void {
  useEffect(() => {
    if (!sessionStorage) return;
    const currentPath = router.asPath;

    if (DONT_ADD_TO_HISTORY_PATTERNS.every((rx) => !rx.test(currentPath))) {
      const onSiteHistory: string[] = getSessionStorage(ON_SITE_HISTORY_STORAGE_KEY, []).slice(
        0,
        ON_SITE_HISTORY_SIZE - 1
      );
      if (currentPath !== onSiteHistory[0]) onSiteHistory.unshift(currentPath);
      setSessionStorage(ON_SITE_HISTORY_STORAGE_KEY, onSiteHistory);
    }
  }, [router.asPath]);
}

/**
 * Adds previous history state to end of stack if on-site.  If previous history state is not available falls back to fallbackUrl.  Similar to router.back but re-adds location to stack.
 * @param router the current router
 * @param fallbackUrl a fallback URL to navigate to if no previous state is available (likely case for off-site)
 * @param ignoreQSDiff ignores query strings when looking for non-matching URLs in history
 * @returns a promise which resolves when the router push operation completes
 */
export async function pushHistoryBack(
  router: PushHistoryBackRouterLike,
  fallbackPath: string | Url,
  ignoreQSDiff = false
): Promise<boolean> {
  if (!sessionStorage) return router.push(fallbackPath);
  const currentPath = removeQS(router.asPath);
  // ignore first item, it should be the same as the current
  const [, ...onSiteHistory] = getSessionStorage(ON_SITE_HISTORY_STORAGE_KEY, []) as string[];
  let prevPath: string | undefined;

  if (ignoreQSDiff) {
    prevPath = onSiteHistory.find((path) => removeQS(path) !== currentPath);
  } else if (currentPath !== onSiteHistory[0]) {
    prevPath = onSiteHistory[0];
  }

  if (!prevPath) return router.push(fallbackPath);
  return router.push(prevPath);
}

export const queryObjectToStrParams = <T extends string>(params: Record<string, T | undefined> | undefined): string => {
  if (params === undefined) return "";

  const strQuery = Object.keys(params).reduce(
    (values, k) => (!!params[k] ? [...values, `${k}=${params[k]}`] : values),
    []
  );

  return strQuery.length ? "?" + strQuery.join("&") : "";
};

export const getHash = (hash: string | undefined): string => (hash ? `#${hash}` : "");

export const getBillingUrl = (action?: TeamRedirectAction): string => `/billing${!!action ? `?action=${action}` : ""}`;

export const HOURS_BASE_PATH = "/settings/hours";

export const getHoursUrl = (query?: { policy?: TimePolicyType; id?: string }): string =>
  `${HOURS_BASE_PATH}${queryObjectToStrParams(query)}`;

export type GeneralSettingsJumpTargets = "buffer" | "travel" | "decompression";
export const getGeneralSettingsUrl = (jumpTarget?: GeneralSettingsJumpTargets) =>
  `/settings/general${jumpTarget ? `#${jumpTarget}` : ""}`;

/**
 * 1:1 routing
 */
export type OneOnOneEditFormId = "schedulingOptions" | "videoConferencing";
export const ONE_ON_ONE_BASE_PATH = "/one-on-ones";
export type OneOnOneTabView = "all" | "active" | "yours" | "invited" | "detected";

export const getOneOnOneListUrl = (filter?: OneOnOneTabView) =>
  `${ONE_ON_ONE_BASE_PATH}${queryObjectToStrParams({ filter })}`;

export const getOneOnOneEditUrl = (oneOnOneId: number, hashTarget?: OneOnOneEditFormId): string =>
  `${ONE_ON_ONE_BASE_PATH}/${oneOnOneId}/edit${getHash(hashTarget)}`;

export const getOneOnOneDetailsUrl = (oneOnOneId: number, query?: Record<string, string>) =>
  `${ONE_ON_ONE_BASE_PATH}/${oneOnOneId}/details${queryObjectToStrParams(query)}`;

/**
 * Sync routing
 */
const SYNC_BASE_URL = "/sync";

export const getSyncHomeUrl = (credentialId?: number): string =>
  `${SYNC_BASE_URL}${credentialId !== undefined ? `?credential=${credentialId}` : ""}`;

export type SyncFormJumpTarget = "custom-visibility";

export const getSyncUrlId = (sourceCalendarId: number, targetCalendarId: number): string =>
  `${sourceCalendarId}-${targetCalendarId}`;

export const getSyncUrl = (sourceCalendarId: number, targetCalendarId: number, jumpTarget?: SyncFormJumpTarget) =>
  `${SYNC_BASE_URL}/${getSyncUrlId(sourceCalendarId, targetCalendarId)}${getHash(jumpTarget)}`;

/**
 * Scheduling link routing
 */

export const SCHEDULING_LINK_BASE_URL = "/scheduling-links";

export const getSchedulingLinksUrl = () => SCHEDULING_LINK_BASE_URL;

export const getSchedulingLinkDetailsUrl = (id: string) => `${SCHEDULING_LINK_BASE_URL}/${id}`;

export type SchedulingLinkEditJumpTargets = "connected-calendar" | "location";

export const getSchedulingLinkEditUrl = (id: string, jumpTarget?: SchedulingLinkEditJumpTargets) =>
  `${SCHEDULING_LINK_BASE_URL}/${id}/edit${getHash(jumpTarget)}`;

export const getSchedulingLinkOneOffEditUrl = (id: string) => {
  return `${SCHEDULING_LINK_BASE_URL}/one-off/${id}/edit`;
};

export const getGroupLink = (schedulingLink: SchedulingLink) => `/m/${schedulingLink.pageSlug}`;

export const getBookingPageLink = (userSlug: string, bookingSlug: string, action?: BookingPageAction) =>
  `/m/${userSlug}/${bookingSlug}` + (action ? `?action=${action}` : "");

export const getSchedulingLinkBookingPageLink = (schedulingLink: SchedulingLink): string =>
  getBookingPageLink(schedulingLink.pageSlug, schedulingLink.slug);

export const getSchedulingLinkMeetingLink = (schedulingLink: SchedulingLink) =>
  new URL(getBookingPageLink(schedulingLink.pageSlug, schedulingLink.slug), window.location.href).toString();

export type MeetingPageAction = "schedule" | "reschedule" | "cancel";

export const getMeetingPageLink = (meetingId: string, tz?: string, action?: MeetingPageAction): string =>
  `/meeting/${meetingId}${queryObjectToStrParams<MeetingPageAction | string>({ tz, action })}`;

export const getMeetingReschedulePageLink = (meetingId: string, tz?: string): string =>
  `${getMeetingPageLink(meetingId)}/reschedule` + (tz ? `?tz=${tz}` : "");

export const getBookingPathForTeamMember = (member: TeamMember): string => `/m/${member.schedulingLinkSlug}`;

export const getBookingLinkForTeamMember = (member: TeamMember): string =>
  new URL(getBookingPathForTeamMember(member), window.location.href).toString();

export const getAuthUrl = (
  baseUri: string,
  router: PushHistoryBackRouterLike,
  state?: Record<string, unknown>
): string => {
  const authUrl = new URL(baseUri, window.location.href);
  authUrl.searchParams.append("state", JSON.stringify({ redirect: router.asPath, ...(state || {}) }));
  return authUrl.toString();
};

/**
 * Onboarding routing
 */
export const USER_ONBOARDING_BASE_PATH = "/onboarding/user";
export const getUserOnboardingPath = (step: UserOnboardingStep = "welcome") => `${USER_ONBOARDING_BASE_PATH}/${step}`;

export type GetMailToUrlOptions = {
  subject?: string;
  body?: string;
};

export const getMailtoUrl = (recipient: string | string[], options?: GetMailToUrlOptions) =>
  `mailto:${(Array.isArray(recipient) ? recipient : [recipient]).join(",")}` + queryObjectToStrParams(options);
/**
 * Quest routing
 */
export const QUESTS_PATH = "/setup-guide";
export const getQuestsUrl = (action?: "onboarded") => `${QUESTS_PATH}${queryObjectToStrParams({ action })}`;

/**
 * Habits routing
 */
export type HabitsViewTab = "all" | "templates";
export const HABITS_BASE_PATH = "/habits";
export const getHabitsPath = (view?: HabitsViewTab) => `${HABITS_BASE_PATH}${queryObjectToStrParams({ view })}`;

export type HabitFormJumpTarget = "custom-visibility";

export const getHabitEditUrl = (id: number, jumpTarget?: HabitFormJumpTarget): string =>
  `${HABITS_BASE_PATH}/${id}/edit${getHash(jumpTarget)}`;

export const getEventViewUrl = (eventKey: string): string => {
  return `${NEXT_PUBLIC_API_BASE_URI}/events/view/${eventKey}`;
};

export const hasValidRoutesWithoutAuth = (router: ReturnType<typeof useOurRouter>): boolean => {
  return (
    ["", "/", "/signup", "/login", "/unauthorized", "/logout", "/offboarding", "/social", "/unsubscribe"].includes(
      router.pathname
    ) ||
    router.pathname.startsWith("/smart-onboarding") ||
    router.pathname.startsWith("/landing") ||
    router.pathname.startsWith("/quick-signup") ||
    router.pathname.startsWith("/m") ||
    /^\/one-on-ones\/(\d{1,})\?/i.test(router.asPath)
  );
};

/**
 * Tasks routing
 */
export const TASKS_BASE_PATH = "/tasks";

export const getTasksUrl = () => TASKS_BASE_PATH;
export const getTaskEditUrl = (id: number | "new") => `${TASKS_BASE_PATH}/${id}/edit`;
export const getTaskDetailsUrl = (id: number) => `${TASKS_BASE_PATH}/${id}`;
