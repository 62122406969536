import { SeverityLevel } from "@sentry/nextjs";
import { useAnalyticsContext } from "../context/AnalyticsContext";
import { Primitive } from "../types";
import { useCallbackSafeRef } from "./useCallbackSafeRef";

type ErrorHandlerProvider = "SENTRY" | "UNKNOWN";

const DEFAULT_ERROR_HANDLER_PROVIDER: ErrorHandlerProvider = "SENTRY";

type ErrorSeverity = SeverityLevel;

type ErrorTags = {
  [key: string]: Primitive;
};

interface CaptureErrorProps {
  severity?: ErrorSeverity;
  tags?: ErrorTags;
  statusCode?: number;
}

interface ErrorImplementation {
  useImplementation: () => (error: unknown, props?: CaptureErrorProps) => void;
}

const SentryErrorProviderImplementation: ErrorImplementation = {
  useImplementation: () => {
    const {
      state: { sentry },
    } = useAnalyticsContext();

    return (error, options = {}) => {
      const { tags, severity = "error", statusCode } = options;
      const tagsMutated = { ...tags };

      if (statusCode) {
        tagsMutated.statusCode = statusCode;
        tagsMutated.isStatusCoded = true;
      }

      // https://docs.sentry.io/platforms/javascript/usage/set-level/
      sentry?.withScope((scope) => {
        scope.setLevel(severity);
        sentry?.captureException(error, { tags: tagsMutated });
      });
    };
  },
};

const LocalErrorProviderImplementation: ErrorImplementation = {
  useImplementation:
    () =>
    (error, options = {}) => {
      switch (options.severity || "error") {
        case "error":
        case "fatal":
          // eslint-disable-next-line no-console
          console.error("CAPTURED ERROR", options.severity, error, options);
          break;
        case "warning":
          // eslint-disable-next-line no-console
          console.warn("CAPTURED ERROR", options.severity, error, options);
          break;
        default:
          // eslint-disable-next-line no-console
          console.log("CAPTURED ERROR", options.severity, error, options);
          break;
      }
    },
};

const ERROR_PROVIDER_IMPLEMENTATION: Record<ErrorHandlerProvider, ErrorImplementation> = {
  SENTRY: SentryErrorProviderImplementation,
  UNKNOWN: LocalErrorProviderImplementation,
};

export const useCaptureError = () => {
  const provider = process.env.NODE_ENV === "development" ? "UNKNOWN" : DEFAULT_ERROR_HANDLER_PROVIDER;

  const runImplementation = ERROR_PROVIDER_IMPLEMENTATION[provider].useImplementation();

  const captureError = useCallbackSafeRef((error, { tags, severity, statusCode }: CaptureErrorProps = {}) => {
    runImplementation({
      error,
      tags,
      severity,
      statusCode,
    });
  });

  return {
    captureError,
  };
};
