import { ReactNode } from "react";
import { concat, filter, fromPromise, map, merge, pipe } from "wonka";
import { Override } from "../types/index";
import { deserialize, upsert } from "../utils/wonka";
import { DayOfWeek } from "./Calendars";
import {
  DailyHabit as HabitDto,
  DailyHabitTemplate as DailyHabitTemplateDto,
  HabitTemplateKey as HabitTemplateKeyDto,
  RecurringAssignmentType,
  SubscriptionType,
  ThinPerson as ThinPersonDto,
  UserProfileDepartment as UserProfileDepartmentDto,
  UserProfileRole as UserProfileRoleDto,
} from "./client";
import { Category, EventColor, EventSubType, PrimaryCategory } from "./EventMetaTypes";
import { dtoToHabit, habitToDto } from "./Habits.mutators";
import { Recurrence } from "./OneOnOnes.types";
import { isHabit as isHabitPlanner, TaskOrHabitIdentifyingFields } from "./Planner";
import { Smurf } from "./Tasks";
import { TimePolicyType, TimePolicy } from "./TimeSchemes.types";
import { NotificationKeyStatus, TransformDomain } from "./types";
import { User, UserProfileDepartment, UserProfileRole } from "./Users";

export type HabitTemplateKey = `${HabitTemplateKeyDto}`;

export type HabitTemplate = Override<
  DailyHabitTemplateDto,
  {
    name: HabitTemplateKey;
    eventCategory?: PrimaryCategory;
    idealTime: string;
    recurrence: Recurrence | null;
    timePolicyType: TimePolicyType;
    oneOffPolicy: TimePolicy;
  }
>;

export const dtoToHabitTemplate = (dto: DailyHabitTemplateDto): HabitTemplate => ({
  ...dto,
  eventCategory: !!dto.eventCategory ? Category.get(dto.eventCategory as unknown as string) : undefined,
  name: dto.name as HabitTemplateKey,
  recurrence: !!dto.recurrence ? Recurrence.get(dto.recurrence) : null,
});

export const isHabit = (item: unknown): item is Habit => isHabitPlanner(item as TaskOrHabitIdentifyingFields);

// WARNING BEFORE EDITING: These values are copied from the server, this is what is set by the server when an undefined value is set
// Be careful changing these to make sure they are in sync with the server in /src/main/java/ai/reclaim/server/assist/TaskOrHabit.java
// TODO: Find a way to add this to OpenAPI (ma)
export const DefaultAutoDeclineText =
  "Hi! This is Reclaim, {name}'s virtual assistant. I'm sorry, but {name} has a commitment at this time, and it's one of the last open slots available to get it done. Can you find another time to meet?";
export const DefaultDefendedDescription =
  "Reclaim has blocked this time off for {name} to work on an important commitment. Reclaim defended this time because it's one of the last available in {name}'s schedule. Please find another time to meet with {name}.";

export enum DefenseAggression {
  None = "NONE",
  Low = "LOW",
  Default = "DEFAULT",
  High = "HIGH",
  Max = "MAX",
}

export const HABIT_DEFENSIVENESS_COPY: Record<DefenseAggression, { label: ReactNode; description: ReactNode }> = {
  NONE: { label: "Always free", description: "Habits will always show as free and available time on your calendar." },
  LOW: {
    label: "Least defensive",
    description:
      "Marks the Habit as busy when one slot remains for its minimum duration, or 30m before the Habit is scheduled to begin.",
  },
  DEFAULT: {
    label: "More defensive",
    description:
      "Marks the Habit as busy when two slots remain for its minimum duration, or 60m before the Habit is scheduled to begin.",
  },
  HIGH: {
    label: "Most defensive",
    description:
      "Marks the Habit as busy when one slot remains for its maximum duration, or 24h before the Habit is scheduled to begin.",
  },
  MAX: { label: "Always busy", description: "Habits will always show as busy and unavailable time on your calendar." },
};

export const HABIT_DEFENSIVENESS_ORDER: DefenseAggression[] = [
  DefenseAggression.None,
  DefenseAggression.Low,
  DefenseAggression.Default,
  DefenseAggression.High,
  DefenseAggression.Max,
];

export type Habit = Override<
  HabitDto,
  {
    readonly id: number;
    readonly effectivePriority?: Smurf;
    readonly created: Date;
    readonly updated?: Date;
    readonly deleted?: boolean;

    title: string;
    index: number;
    enabled: boolean;
    eventCategory: PrimaryCategory;
    eventColor?: EventColor;
    idealDay?: DayOfWeek | null;
    recurrence: Recurrence | null;
    priority?: Smurf;
    snoozeUntil?: Date | null;
    defenseAggression: DefenseAggression;
    eventSubType: EventSubType;
    timesPerPeriod?: number;
    invitees: ThinPersonDto[];
    recurringAssignmentType: RecurringAssignmentType;
    timeSchemeId?: string | null;
    timePolicyType?: TimePolicyType;
    oneOffPolicy?: TimePolicy | null;
  }
>;

const DailyHabitSubscription = {
  subscriptionType: SubscriptionType.DailyHabit,
};

export class HabitsDomain extends TransformDomain<Habit, HabitDto> {
  resource = "Habit";
  cacheKey = "habits";
  pk = "id";

  public serialize = habitToDto;
  public deserialize = dtoToHabit;

  watchWs$ = pipe(
    this.ws.subscription$$(DailyHabitSubscription),
    filter((envelope) => !!envelope.data),
    map((envelope) => envelope.data),
    deserialize(this.deserialize)
  );

  watchAll$ = pipe(
    merge([this.upsert$, this.watchWs$]),
    map((items) => this.patchExpectedChanges(items))
  );

  list$$ = () =>
    pipe(
      fromPromise(this.list()),
      map((items) => this.patchExpectedChanges(items))
    );

  listAndWatch$$ = () => {
    return pipe(
      concat<Habit[] | null>([this.list$$(), this.watchAll$]),
      upsert((h) => this.getPk(h)),
      map((items) => [...items])
    );
  };

  watchId$$ = (id: number) => {
    return pipe(
      this.listAndWatch$$(),
      map((items) => items?.find((i) => i.id === id))
    );
  };

  list = this.deserializeResponse(this.api.assist.getDailyHabits);

  get = this.deserializeResponse((id: number) => this.api.assist.getDailyHabit(id));

  create = this.deserializeResponse((habit: Habit) => {
    const notificationKey = this.generateUid("create");

    this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, true);

    return this.api.assist
      .create(this.serialize(habit) as HabitDto, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  patch = this.deserializeResponse((habit: Partial<Habit>) => {
    const { id, ...rest } = habit;
    const notificationKey = this.generateUid("patch", habit);

    this.expectChange(notificationKey, id!, rest, true);

    return this.api.assist
      .patch(id!, this.serialize(rest) as HabitDto, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  delete = (id: number) => {
    const notificationKey = this.generateUid("delete", id);

    this.expectChange(notificationKey, id, { deleted: true });

    return this.api.assist
      .delete1(id, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  getHabitTemplate = this.typedManageErrors(async (templateKey: HabitTemplateKey) => {
    const template = await this.api.assist.getHabitTemplate({ templateKey: templateKey as HabitTemplateKeyDto });
    return dtoToHabitTemplate(template);
  });

  getHabitTemplates = this.typedManageErrors(async (role?: UserProfileRole, department?: UserProfileDepartment) =>
    (
      await this.api.assist.getHabitTemplates({
        role: role ? (role as UserProfileRoleDto) : null,
        department: (department as UserProfileDepartmentDto) || undefined,
      })
    ).map(dtoToHabitTemplate)
  );

  createHabitTemplates = this.typedManageErrors(
    async (templates?: HabitTemplateKey[]) =>
      await this.api.assist.createHabitTemplates({ templates: templates as HabitTemplateKeyDto[] })
  );
}

export const defaultHabit: Partial<Habit> = {
  title: "",
  eventCategory: PrimaryCategory.Personal,
  eventColor: EventColor.Auto,
  enabled: true,
  defenseAggression: DefenseAggression.Default,
  durationMin: 15,
  durationMax: 120,
  idealTime: "09:00:00",
  recurrence: Recurrence.Weekly,
  timePolicyType: "WORK",
  alwaysPrivate: false,
  invitees: [],
  index: 0,
};

export function userDefaultHabit(user?: User | null, overrides: Partial<Habit> = {}): Partial<Habit> {
  // additionalDescription gets used in google cal, and that requires no line breaks or extra spaces, so ensure its clean here
  if (overrides.additionalDescription) {
    overrides.additionalDescription = overrides.additionalDescription
      .replace(/(\r\n|\n|\r)/gm, "")
      .replace(/[\t ]+\</g, "<")
      .replace(/\>[\t ]+\</g, "><")
      .replace(/\>[\t ]+$/g, ">");
  }

  return { ...defaultHabit, ...overrides };
}
